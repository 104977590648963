@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  font-family: "fieldwork", sans-serif;
  padding: 0px !important;
}

.carCategory, .reasonCards, .mobileScroll {
  overflow-y: scroll;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

.carCategory::-webkit-scrollbar, .reasonCards::-webkit-scrollbar, .mobileScroll::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.scrollbar_for_firefox {
  display: none;
}

input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
}

input[type="radio"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 20px;
  height: 20px;
  border: 2px solid #d1d1db;
  border-radius: 50%;
  transform: translateY(-0.075em);
}

input[type="radio"] {
  /* ...existing styles */

  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}

input[type="radio"] {
  /* ...existing styles */
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]::before {
  /* ...existing styles */

  /* Windows High Contrast Mode */
  background-color: #fff;
  border: 5px solid #1e3a8a;
}

.view-details-btn {
  color: black !important;
  font-weight: 400 !important;
  font-size: 12px !important;
}

.resend-btn {
  color: #0b0b0b !important;
  font-weight: 400 !important;
  font-size: 18px !important;
}

.booking-dashboard-btn:hover {
  background-color: #404040 !important;
}

.booking-dashboard-btn {
  background-color: #404040 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.loader {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.input-focus {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 0px;
  background-color: black;
  transition: width 0.3s;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.input-focus-error {
  background-color: red;
  width: 10px;
}

.input-element:focus+.input-focus {
  width: 10px;
}

.input-element:focus+.input-focus-error {
  background-color: black;
  width: 10px;
}

.PhoneInput-div::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 0;
  background-color: black;
  transition: width 0.3s;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-right-radius: 5px;

}

.PhoneInput-div-error::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 10px;
  background-color: red;
  transition: width 0.3s;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-right-radius: 5px;
}


.PhoneInput-div:focus-within::after {
  width: 10px;
}

.PhoneInputInput {
  outline: none;
}

.image-container {
  max-width: 1090px;
}

.second-image-container {
  max-width: 38.5%;
}

@media (min-width:1800px) {

  .image-container {
    max-width: 900px;
  }

}

@media (max-height:900px) {

  .image-container {
    max-width: 840px;
  }

  .second-image-container {
    max-width: 37.98%;
  }
}



@media (max-height:850px) {

  .image-container {
    max-width: 780px;
  }

  .second-image-container {
    max-width: 37.98%;
  }
}



@media (max-width:1601px) {

  .image-container {
    max-width: 800px;
  }
}


@media (max-width:1536px) {

  .image-container {
    max-width: 750px;
  }
}

@media (max-width:1370px) {

  .image-container {
    max-width: 650px;
  }

  .second-image-container {
    max-width: 37.5%;
  }
}

.brightness-filter {
  filter: brightness(0);
}

.error-shadow {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 1px #DC2020;
}

.privacy-container ol {
  list-style: decimal;
}

.privacy-container ol>li {
  margin-top: 40px;
  color: #3A4656;
  font-size: 18px;
  margin-left: 20px;

}

.privacy-container ul {
  list-style: decimal;
}

.privacy-container ul>li {
  margin-top: 40px;
  color: #3A4656;
  font-size: 18px;
  margin-left: 30px;

}

